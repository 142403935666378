import Model            from '/lib/model'
import currencies       from '/lib/currencies'
import { formatMoney }  from 'accounting'
import humanize         from 'underscore.string/humanize'
import { stripZone }    from '/lib/datetime'

export default \
class Ticket extends Model
  @className: 'Ticket'

  @belongsTo 'listing', model: 'Listing'
  @belongsTo 'event',   model: 'Event'
  @belongsTo 'user',    model: 'User'

  @hasMany 'cost_items',    model: 'CostItem'
  @hasMany 'installments',  model: 'Installment'
  @hasMany 'host_fields',   model: 'HostField'

  @computed 'formattedPrice', ->
    return 'Free' unless @price
    formatMoney @price, symbol: currencies[@base_currency].replace @base_currency, ''

  @computed 'url', ->
    "#{process.env.MULTIVERSE}/tickets/#{@id}"

  @computed 'startTime', ->
    stripZone @start_time

  @computed 'endTime', ->
    stripZone @end_time

  @computed 'createdAt', ->
    stripZone @created_at

  @computed 'transitions', ->
    # collect all possible state transitions
    actions = state_actions
    if @listing?.has_seats
      actions[@state] = [actions[@state]..., unique_seats_actions[@state]...]
    if @using_payment_plan
      actions[@state] = [actions[@state]..., payment_plan_actions[@state]...]

    ret = {}
    for tr in actions[@state] or []
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = [
  'unapproved'
  'checkout'
  'unpaid'
  'pending'
  'paid'
  'failed'
  'declined'
  'cancelled'
  'ended'
  'closed'
  'expired'
  'error'
  'partially_paid'
  'disputed'
  'needs_pre_auth'
]

export state_options = value: state, label: humanize state for state in states

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions =
  unapproved:     $w ''
  checkout:       $w ''
  unpaid:         $w ''
  pending:        $w 'retry_pay'
  paid:           $w 'resend_ticket --- admin_void admin_void_without_refund! cancel!'
  failed:         $w ''
  declined:       $w ''
  cancelled:      $w ''
  ended:          $w 'resend_ticket --- admin_void admin_void_without_refund! cancel!'
  closed:         $w 'resend_ticket --- admin_void admin_void_without_refund! cancel!'
  expired:        $w ''
  error:          $w 'admin_void admin_void_without_refund! mark_error_as_expired retry_pay resolve_error'
  partially_paid: $w 'admin_void admin_void_without_refund! cancel!'
  disputed:       $w ''
  needs_pre_auth: $w ''

export unique_seats_actions =
  unapproved:     $w ''
  checkout:       $w ''
  unpaid:         $w ''
  pending:        $w ''
  paid:           $w 'admin_void_without_releasing_seats! admin_void_without_refund_or_releasing_seats! cancel_without_releasing_seats!'
  failed:         $w ''
  declined:       $w ''
  cancelled:      $w ''
  ended:          $w 'admin_void_without_releasing_seats! admin_void_without_refund_or_releasing_seats! cancel_without_releasing_seats!'
  closed:         $w 'admin_void_without_releasing_seats! admin_void_without_refund_or_releasing_seats!'
  expired:        $w ''
  error:          $w 'admin_void_without_releasing_seats! admin_void_without_refund_or_releasing_seats!'
  partially_paid: $w 'admin_void_without_releasing_seats! admin_void_without_refund_or_releasing_seats! cancel_without_releasing_seats!'
  disputed:       $w ''

export payment_plan_actions =
  unapproved:     $w ''
  checkout:       $w ''
  unpaid:         $w ''
  pending:        $w ''
  paid:           $w 'admin_void_loan_fee!'
  failed:         $w ''
  declined:       $w ''
  cancelled:      $w 'admin_void_loan_fee!'
  ended:          $w 'admin_void_loan_fee!'
  closed:         $w 'admin_void_loan_fee!'
  expired:        $w ''
  error:          $w 'admin_void_loan_fee!'
  partially_paid: $w 'admin_void_loan_fee!'
  disputed:       $w ''

export transition_name_overrides =
  retry_pay: "Retry payment"
  resolve_error: 'Resolve error'
  mark_error_as_expired: 'Mark expired'
  admin_void: 'Void - Refund buyer'
  'admin_void_without_releasing_seats!': 'Void - Refund buyer without releasing seats'
  'admin_void_without_refund!': 'Void - No buyer refund & Host funds removed'
  'admin_void_without_refund_or_releasing_seats!': 'Void - No buyer refund, Host funds removed without releasing seats'
  'admin_void_loan_fee!': 'Refund Payment Plan Fee'
  'cancel!': 'Void - Host keeps funds'
  'cancel_without_releasing_seats!': 'Void - Host keeps funds without releasing seats'
