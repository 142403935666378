import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import compact from 'underscore-es/compact'

import {
  Link
  NavLink
  withRouter
  matchPath
}                     from 'react-router-dom'
import FaChevronDown  from 'react-icons/lib/fa/chevron-down'
import Avatar         from '/components/avatar'
import DropdownMenu   from '/components/dropdown-menu'
import withAbility    from '/lib/with-ability'

export default \
withRouter \
withAbility \
class NavBar extends React.Component
  @propTypes:
    user:     PropTypes.object.isRequired
    signOut:  PropTypes.func.isRequired

  isActive: (path,index=false)->
    'active' if @props.router.isActive path, index

  render: ->
    <nav className="Navbar">
      {@userDropdown()}
      {@general()}
      {@config()}
      {@ccpa()}
    </nav>

  navItem: (name, path)->
    return null unless name and path
    <NavLink to={path}>
      {name}
    </NavLink>

  navSection: (name, items=[])->
    items = compact items
    return null unless name and items.length
    <section title={name} key={name}>
      <ul>
        {for item, idx in items
          <li key={idx}>{item}</li>
        }
      </ul>
    </section>

  general: ->
    @navSection 'General', [
      @navItem 'Listings',          '/listings'
      @navItem 'Events',            '/events'
      @navItem 'Users',             '/users'
      @navItem 'Tickets',           '/tickets'
      @navItem 'Transfers',         '/transfers'
      @navItem 'Payment Plans',     '/payment-plans'
      @navItem('Withdrawals',       '/withdrawals')       if @props.can 'read', 'Withdrawal'
      @navItem('Chargebacks',       '/chargebacks')       if @props.can 'read', 'Chargeback'
      @navItem 'Emails',            '/emails'
      @navItem('Advances',          '/advances')          if @props.can 'read', 'Advance'
      @navItem('Attractions',       '/attractions')       if @props.can 'read', 'Attraction'
      @navItem('Service Invoices',  '/service-invoices')  if @props.can 'read', 'ServiceInvoice'
      @navItem 'Notes',             '/notes'
      @navItem 'Report Logs',       '/report-logs'
    ]

  config: ->
    @navSection 'Config', [
      @navItem('Per Item Fees',   '/fees/per-item')       if @props.can 'read', 'CommissionCode'
      @navItem('Per Order Fees',  '/fees/per-order')      if @props.can 'read', 'OrderFee'
      @navItem('Countries',         '/countries')         if @props.can 'read', 'Country'
      @navItem('ICP Queue',         '/icp-queue')         if @props.can 'update_icp', 'User'
    ]

  ccpa: ->
    @navSection 'Ccpa', [
      @navItem('Manual Disclose',  '/ccpa/manual-disclose')
    ]

  userDropdown: ->
    toggle = <div>
      <Avatar user={@props.user} size={36}/>
      <span className="name">{@props.user.name}</span>
      <FaChevronDown style={fontSize: '0.5em', verticalAlign: 'middle', marginLeft: '1em'}/>
    </div>

    <div className="user">
      <DropdownMenu
        toggle={toggle}
        actions={{'Sign Out': @props.signOut }}
      />
    </div>
